import {apiSlice, parseQueryParams} from "../api/apiSlice";
import {ResultList} from "../../types/ResultList";
import {FichaTecnica} from "../../types/FichaTecnica";
import {QueryParams} from "../../types/QueryParams";

const endpointUrl: string = "/fichas-tecnicas";


function listFichasTecnicasUrl({ page = 0, perPage = 10, search = "" }) {
    const params = { page, perPage, search};
    return `${endpointUrl}?${parseQueryParams(params)}`;
}

function createFichaTecnicaMutation(fichaTecnica: FichaTecnica) {
    return { url: endpointUrl, method: "POST", body: fichaTecnica };
}

function updateFichaTecnicaMutation(fichaTecnica: FichaTecnica) {
    return {
        url: `${endpointUrl}/${fichaTecnica.id}`,
        method: "PUT",
        body: fichaTecnica,
    };
}

function deleteFichaTecnicaMutation(id: string) {
    return {
        url: `${endpointUrl}/${id}`,
        method: "DELETE"
    };
}

export const fichasTecnicasApiSlice = apiSlice.injectEndpoints({
    endpoints: ({query, mutation}) => ({
        getFichasTecnicas: query<ResultList<FichaTecnica>, QueryParams>({
            query: listFichasTecnicasUrl,
            providesTags: ["Produto"]
        }),
        getFichaTecnica: query<FichaTecnica, { id : string }>({
            query: ({id}) => `${endpointUrl}/${id}`,
            providesTags: ["Produto"]
        }),
        createFichaTecnica: mutation<void, FichaTecnica>({
            query: createFichaTecnicaMutation,
            invalidatesTags: ["Produto"],
        }),
        updateFichaTecnica: mutation<void, FichaTecnica>({
            query: updateFichaTecnicaMutation,
            invalidatesTags: ["Produto"],
        }),
        deleteFichaTecnica: mutation<void, string>({
            query: deleteFichaTecnicaMutation,
            invalidatesTags: ["Produto"],
        }),
    }),
});

export const {
    useGetFichasTecnicasQuery,
    useGetFichaTecnicaQuery,
    useCreateFichaTecnicaMutation,
    useUpdateFichaTecnicaMutation,
    useDeleteFichaTecnicaMutation
} = fichasTecnicasApiSlice