import {Box, Paper, SelectChangeEvent, Typography} from '@mui/material';
import 'dayjs/locale/pt-br';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import {useGetFichaTecnicaQuery, useUpdateFichaTecnicaMutation} from "./produtoSlice";
import {FichaTecnica, QuantidadeInsumo} from "../../types/FichaTecnica";
import ProdutoForm from "./components/ProdutoForm";

export default function EditProduto() {
    const id = useParams().id ?? "";
    const navigate = useNavigate();

    const {data: fichaTecnica, isLoading} = useGetFichaTecnicaQuery({id});

    const [isDisabled, setIsDisabled] = useState(false);

    const [updateFichaTecnica, status] = useUpdateFichaTecnicaMutation()

    useEffect(() => {
        if (status.isSuccess) {
            enqueueSnackbar("Ficha técnica atualizada com successo", {variant: "success"});
            setIsDisabled(true);
            navigate(-1);
        }
        if (status.isError) {
            enqueueSnackbar("Não foi possível ataualizar a ficha técnica", {variant: "error"});
        }
    }, [enqueueSnackbar, status.isError, status.isSuccess]);

    return (
        <Box>
            <Paper>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant='h4'>Editar produto</Typography>
                    </Box>
                </Box>

            </Paper>
        </Box>
    )
}
