import {Box, Paper, SelectChangeEvent, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import ProdutoForm from "./components/ProdutoForm";
import {useNavigate} from "react-router-dom";
import {enqueueSnackbar} from "notistack";
import {useCreateFichaTecnicaMutation} from "./produtoSlice";
import {FichaTecnica, QuantidadeInsumo} from "../../types/FichaTecnica";

export default function CreateProduto() {

    const navigate = useNavigate();

    const [isDisabled, setIsDisabled] = useState(false);

    const [createFichaTecnica, status] = useCreateFichaTecnicaMutation()

    useEffect(() => {
        if (status.isSuccess) {
            enqueueSnackbar("Ficha técnica cadastrada com successo", {variant: "success"});
            setIsDisabled(true);
            navigate(-1);
        }
        if (status.isError) {
            enqueueSnackbar("Não foi possível cadastrar a ficha técnica", {variant: "error"});
        }
    }, [enqueueSnackbar, status.isError, status.isSuccess]);

    return (
        <Box>
            <Paper>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant='h4'>Novo produto</Typography>
                    </Box>
                </Box>

            </Paper>
        </Box>
    )
}