import React from 'react'
import {Box} from "@mui/material";
import FichaTecnicaCard from "../fichastecnicas/FichaTecnicaCard";
import InsumoCard from "../insumos/InsumoCard";
import EmbalagemCard from "../embalagens/EmbalagemCard";
import ProdutoCard from "../produtos/ProdutoCard";
import {useAdminStatus, useAuthToken} from "../../app/hooks";

export default function HomePage(){
    const { isAdmin, loading } = useAdminStatus();
    const { token } = useAuthToken();

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            flexWrap: 'wrap',
            '@media (max-width: 768px)': {
                flexDirection: 'column', // for mobile screens, display cards in a column
                flexWrap: 'wrap',
                justifyContent: 'space-between',
            },
        }}>

            {isAdmin &&
            <>
                <InsumoCard />
                <FichaTecnicaCard />
                <EmbalagemCard />
                <ProdutoCard />
            </>
            }
        </Box>
    );
}