import React, {useEffect, useState} from "react";
import {FichaTecnica, QuantidadeInsumo} from "../../../types/FichaTecnica";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent, CircularProgress,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    styled,
    TextField,
    Typography
} from "@mui/material";
import {categorias, findCategoria} from "../../../types/Categoria";
import {findUnidadeComercial, unidadesComerciais} from "../../../types/UnidadeComercial";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import './FichaTecnicaForm.css';
import {Link} from "react-router-dom";
import {InsumoResponse} from "../../../types/Insumo";
import {useGetInsumosQuery} from "../../insumos/insumoSlice";
import FichaTecnicaFormSkeleton from "./FichaTecnicaFormSkeleton";
import {green} from "@mui/material/colors";


type Props = {
    fichaTecnica: FichaTecnica,
    modoPreparoState: string[],
    setModoPreparoState: (value: (((prevState: string[]) => string[]) | string[])) => void,
    quantidadeInsumosState: QuantidadeInsumo[],
    setQuantidadeInsumosState: (value: (((prevState: QuantidadeInsumo[]) => QuantidadeInsumo[]) | QuantidadeInsumo[])) => void,
    isUpdating?: boolean,
    isLoading?: boolean,
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    handleChange: (e: React.ChangeEvent<HTMLInputElement> ) => void;
    handleChangeOnEnum: (event: any, name:string , newValue: { label: string, option: string } | null) => void;
    handleModoPreparoChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
}
const CardContentNoPadding = styled(CardContent)(`
      padding: 16px;
      &:last-child {
        padding-bottom: 16px;
      }
    `);

type ModoPreparoCardInputFormProps = {
    modoPreparo: string[],
    handleModoPreparoChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void,
    removeModoPreparo: (index: number) => void
}

const ModoPreparoCardInputForm: React.FC<ModoPreparoCardInputFormProps> = ({ modoPreparo, handleModoPreparoChange, removeModoPreparo }) => {
    return (
        <>
            {[...modoPreparo].map((instrucao, index) => (
                <Card variant="outlined" key={"card-modo-preparo-"+index} className="bg-form-list" sx={{marginTop: '10px'}}>
                    <CardContentNoPadding>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={11}>
                                <TextField required
                                           key={"card-instrucao-"+index}
                                           name="instrucao"
                                           label={`Passo ${index + 1}`}
                                           size="small"
                                           multiline
                                           value={instrucao}
                                           onChange={(e) => {handleModoPreparoChange(e, index)}}
                                           rows={4}
                                           fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <IconButton aria-label="delete" color="error" onClick={() => removeModoPreparo(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </CardContentNoPadding>
                </Card>
            ))}
        </>
    );
}

type QuantidadeInsumosCardInputFormProps = {
    quantidadeInsumos: QuantidadeInsumo[],
    setQuantidadeInsumos: (value: (((prevState: QuantidadeInsumo[]) => QuantidadeInsumo[]) | QuantidadeInsumo[])) => void,
    removeQuantidadeInsumos: (index: number) => void,
    insumos: InsumoResponse[],
    isFetching: boolean
}

const QuantidadeInsumosCardInputForm: React.FC<QuantidadeInsumosCardInputFormProps> = (
    { quantidadeInsumos, setQuantidadeInsumos, removeQuantidadeInsumos, insumos, isFetching }) => {
    const [inputValues, setInputValues] = useState(Array(quantidadeInsumos.length).fill(''));

    const handleInputChange = (index: number, newValue: string) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = newValue;
        setInputValues(newInputValues);
    };
    return (
        <>
            {quantidadeInsumos.map((row, index) => {
                console.log(row);
                return (
                <Card variant="outlined" key={index} className="bg-form-list" sx={{marginTop: '10px'}}>
                    <CardContentNoPadding>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    id="combo-box-insumos"
                                    size="small"
                                    fullWidth
                                    autoComplete
                                    includeInputInList
                                    filterSelectedOptions
                                    loading={isFetching}
                                    noOptionsText="Nenhum insumo cadastrado"
                                    options={insumos}
                                    onChange={(event, value, reason) => {
                                        setQuantidadeInsumos(
                                            quantidadeInsumosState => {
                                                const updatedQuantidadeInsumos = [...quantidadeInsumosState];

                                                if (!updatedQuantidadeInsumos[index] || value == null) {
                                                    updatedQuantidadeInsumos[index] = {
                                                        insumoID: "",
                                                        quantidade: {
                                                            valor: 0,
                                                            unidade: ""
                                                        }
                                                    };
                                                }

                                                updatedQuantidadeInsumos[index] = { ...updatedQuantidadeInsumos[index], insumoID: value?.id };

                                                // Update unidadeComercial based on selected insumo
                                                const selectedInsumo = insumos.find(insumo => insumo.id === value?.id);

                                                if (selectedInsumo) {
                                                    updatedQuantidadeInsumos[index] = {
                                                        ...updatedQuantidadeInsumos[index],
                                                        quantidade: {
                                                            valor: updatedQuantidadeInsumos[index].quantidade.valor,
                                                            unidade: value?.quantidade.unidade
                                                        }
                                                    }
                                                }

                                                return updatedQuantidadeInsumos;
                                            }
                                        )
                                    }}

                                    inputValue={inputValues[index] || ''}
                                    onInputChange={(event, newInputValue) => {
                                        handleInputChange(index, newInputValue);
                                    }}
                                    value={insumos.find((insumo) => insumo.id === row.insumoID) || null }
                                    getOptionLabel={(option) => option.descricao}
                                    renderInput={(params) => <TextField {...params} required label="Insumo" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField required
                                           name="quantidade"
                                           label="Quantidade"
                                           size="small"
                                           fullWidth
                                           onChange={(event) => {
                                               const value = event.target.value;
                                               setQuantidadeInsumos(
                                                   quantidadeInsumosState => {
                                                       const updatedQuantidadeInsumos = [...quantidadeInsumosState];

                                                       if (!updatedQuantidadeInsumos[index]) {
                                                           updatedQuantidadeInsumos[index] = {
                                                               insumoID: "",
                                                               quantidade: {
                                                                   valor: 0,
                                                                   unidade: ""
                                                               }
                                                           };
                                                       }

                                                       updatedQuantidadeInsumos[index] = {
                                                           ...updatedQuantidadeInsumos[index],
                                                           quantidade: {
                                                               valor: parseFloat(value),
                                                               unidade: updatedQuantidadeInsumos[index].quantidade.unidade
                                                           }
                                                       };

                                                       return updatedQuantidadeInsumos;
                                                   }
                                               )
                                           }}
                                           value={row?.quantidade?.valor}
                                           inputProps={{inputMode:"numeric", pattern:"[0-9]*", type: "number"}}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Autocomplete
                                    id="combo-box-unidade-ingrediente"
                                    size="small"
                                    fullWidth
                                    options={unidadesComerciais}
                                    value={findUnidadeComercial(row?.quantidade?.unidade) || null }
                                    readOnly={true}
                                    onChange={(event, value) => {
                                        setQuantidadeInsumos(
                                            quantidadeInsumosState => {
                                                const updatedQuantidadeInsumos = [...quantidadeInsumosState];

                                                if (!updatedQuantidadeInsumos[index]) {
                                                    updatedQuantidadeInsumos[index] = {
                                                        insumoID: "",
                                                        quantidade: {
                                                            valor: 0,
                                                            unidade: ""
                                                        }
                                                    };
                                                }

                                                updatedQuantidadeInsumos[index] = {
                                                    ...updatedQuantidadeInsumos[index],
                                                    quantidade: {
                                                        valor: updatedQuantidadeInsumos[index].quantidade.valor,
                                                        unidade: value?.option
                                                    }
                                                };

                                                return updatedQuantidadeInsumos;
                                            }
                                        )
                                    }}
                                    renderInput={(params) => <TextField {...params} required label="Unidade" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <IconButton aria-label="delete" color="error" onClick={() => removeQuantidadeInsumos(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </CardContentNoPadding>
                </Card>
            )})}
        </>
    );
}

export default function FichaTecnicaForm({
    fichaTecnica,
    modoPreparoState,
    setModoPreparoState,
    quantidadeInsumosState,
    setQuantidadeInsumosState,
    isUpdating = false,
    isLoading = false,
    handleSubmit,
    handleChange,
    handleChangeOnEnum,
    handleModoPreparoChange,
} : Props ) {


    const [insumos, setInsumos] = useState<InsumoResponse[]>([]);

    const [options, setOptions] = useState({
        page: 0,
        search: "",
        perPage: 200,
    });

    const {data, isFetching, error} = useGetInsumosQuery(options);

    const addQuantidadeInsumos = () => {
        setQuantidadeInsumosState([...quantidadeInsumosState,  { insumoID: "", quantidade: {unidade: "", valor: 0}}]);
    };

    const removeQuantidadeInsumos = (index: number) => {
        setQuantidadeInsumosState(quantidadeInsumosState.filter((row, i) => i !== index));
    };

    const addModoPreparo = () => {
        setModoPreparoState([...modoPreparoState, ""]);
    };

    const removeModoPreparo = (index: number) => {
        //remove index from array and update all "ordem" field to its respecting position on array
        const newModoPreparo = modoPreparoState.filter((_, i) => i !== index);
        setModoPreparoState(newModoPreparo);
    };

    useEffect(() => {
        setOptions({ ...options, search: "" });

        let insumosArray = data?.items.map((insumo) => ({
            id: insumo.id,
            descricao: insumo.descricao,
            quantidade: insumo.quantidade,
            custo: insumo.custo,
        }));
        setInsumos(insumosArray ?? []);

    }, [fichaTecnica, data]);

    if (isLoading || isFetching) {
        return <FichaTecnicaFormSkeleton />
    }



    return (
        <Box p={2}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                            <TextField required
                                       name="descricao"
                                       value={fichaTecnica?.descricao}
                                       label="Título"
                                       size="small"
                                       onChange={handleChange}
                                       inputProps={{ maxLength: 255 }}
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth size="small">
                            <Autocomplete
                                disablePortal
                                id="combo-box-categoria"
                                size="small"
                                value={findCategoria(fichaTecnica?.categoria) || null }
                                options={categorias}
                                onChange={(event, newValue) => {
                                    handleChangeOnEnum(event, 'categoria', newValue)
                                }}
                                renderInput={(params) => <TextField {...params} required label="Categoria" />}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card variant="outlined">
                            <CardContent>
                                <FormLabel component="legend">Rendimento</FormLabel>
                                    <FormControl fullWidth >
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <TextField required
                                                           name="quantidadeRendimento"
                                                           label="Quantidade"
                                                           size="small"
                                                           onChange={handleChange}
                                                           value={fichaTecnica?.rendimento?.valor}
                                                           fullWidth
                                                           inputProps={{inputMode:"numeric", pattern:"[0-9]*", type: "number"}}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Autocomplete
                                                    id="combo-box-unidade"
                                                    size="small"
                                                    fullWidth
                                                    value={findUnidadeComercial(fichaTecnica?.rendimento?.unidade) || null}
                                                    options={unidadesComerciais}
                                                    onChange={(event, newValue) => {
                                                        handleChangeOnEnum(event, 'unidadeRendimento', newValue)
                                                    }}
                                                    renderInput={(params) => <TextField {...params} required label="Unidade Comercial" />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider  />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h5" component="div">
                            Ingredientes
                        </Typography>
                        <Box mt={2}>
                            <QuantidadeInsumosCardInputForm
                                quantidadeInsumos={quantidadeInsumosState}
                                setQuantidadeInsumos={setQuantidadeInsumosState}
                                removeQuantidadeInsumos={removeQuantidadeInsumos}
                                insumos={insumos}
                                isFetching={isFetching}
                            />
                        </Box>
                        <Button sx={{marginTop: '32px'}} variant="contained" color="primary" size="small" onClick={addQuantidadeInsumos}>Adicionar</Button>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h5" component="div">
                            Modo de preparo
                        </Typography>
                        <Box mt={2}>
                            <ModoPreparoCardInputForm modoPreparo={modoPreparoState} handleModoPreparoChange={handleModoPreparoChange} removeModoPreparo={removeModoPreparo}/>
                        </Box>
                        <Button sx={{marginTop: '32px'}} variant="contained" color="primary" size="small" onClick={addModoPreparo}>Adicionar</Button>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={12} mb={5}>
                        <Box display="flex" justifyContent={"space-between"}>
                            <Button variant='contained' component={Link} to="/fichas-tecnicas">
                                Voltar
                            </Button>
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button variant='contained' type="submit" color='secondary'
                                        disabled={isUpdating}
                                >
                                    Salvar
                                </Button>
                                {isUpdating && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}