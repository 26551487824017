import {apiSlice} from "./apiSlice";

const endpointUrl: string = "/ping";


export const pingApiSlice = apiSlice.injectEndpoints({
    endpoints: ({ mutation }) => ({
        ping: mutation<void, void>({
            query: () => ({
                url: `${endpointUrl}`,
                method: 'GET',
            })
        }),
    }),
});

export const { usePingMutation } = pingApiSlice;