import {Cliente} from "../../../types/Cliente";
import {
    DataGrid,
    GridColDef,
    GridFilterModel,
    GridRenderCellParams,
    GridRowsProp,
    GridToolbar
} from "@mui/x-data-grid";
import {Link} from "react-router-dom";
import {Box, IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import dayjs from "dayjs";
import {ResultList} from "../../../types/ResultList";

type Props = {
    data: ResultList<Cliente> | undefined,
    perPage: number,
    isFetching: boolean,
    rowsPerPage?: number

    handleOnPageChange: (page: number) => void;
    handleFilterChange: (filterModel: GridFilterModel) => void;
    handleOnPageSizeChange: (perPage: number) => void;

}

export default function ClienteTable({
                                         data,
                                         perPage,
                                         isFetching,
                                         rowsPerPage,
                                         handleOnPageChange,
                                         handleFilterChange,
                                         handleOnPageSizeChange
                                     }: Props) {


    const rows: GridRowsProp = data ? data.items.map((cliente) => ({
        id: cliente.id,
        nome: cliente.nome,
        telefone: cliente.telefone,
        cpf: cliente.cpf,
        email: cliente.email,
        data_aniversario: cliente.data_nascimento ? dayjs(cliente.data_nascimento).format('DD/MM/YYYY') : ""
    })) : [];

    const nomeColumn: GridColDef = {
        field: 'nome',
        headerName: 'Nome',
        flex: 1
    }

    const emailColumn: GridColDef = {
        field: 'email',
        headerName: 'E-mail',
        flex: 1
    }

    const telefoneColumn: GridColDef = {
        field: 'telefone',
        headerName: 'Telefone',
        flex: 1,
        valueGetter: ( value: string ) => value && `(${value.substring(0,2)}) ${value.substring(2,7)}-${value.substring(7)}`,
    }

    const cpfColumn: GridColDef = {
        field: 'cpf',
        headerName: 'CPF',
        flex: 1,
        valueGetter: ( value: string ) => value && `${value.substring(0,3)}.${value.substring(3,6)}.${value.substring(6,9)}-${value.substring(9,11)}`,
    }

    const dataAniversarioColumn: GridColDef = {
        field: 'data_aniversario',
        headerName: 'Data de Aniversário',
        flex: 1
    }

    const acoesColumn: GridColDef = {
        field: 'id',
        headerName: 'Ações',
        renderCell: renderActionsCell
    }


    const columnsDesktop: GridColDef[] = [nomeColumn, telefoneColumn, emailColumn, cpfColumn, dataAniversarioColumn, acoesColumn];

    const columnsMobile: GridColDef[] = [nomeColumn, telefoneColumn, acoesColumn];

    function renderActionsCell(params: GridRenderCellParams) {
        return (
            <Link
                to={`/clientes/${params.id}/edit`}
            >
                <IconButton
                    color='secondary'
                    aria-label="edit"
                    size="small"
                >
                    <EditIcon fontSize="small"/>
                </IconButton>
            </Link>
        );
    }

    const componentProps = {
        toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
                debounceMs: 500
            }
        },
    }

    const rowCount = data?.total ?? 0;


    return (
        <Box>
            <Box sx={{
                flexGrow: 1,
                display: {xs: 'none', md: 'flex'},
                height: '70%',
                width: '100%'
            }}>
                <DataGrid
                    columns={columnsDesktop}
                    density="compact"
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    disableRowSelectionOnClick={true}
                    filterMode="server"
                    initialState={{
                        pagination: {
                            paginationModel: {pageSize: perPage, page: 0},

                        }
                    }}
                    loading={isFetching}
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationMode="server"
                    rowCount={rowCount}
                    rows={rows}
                    slotProps={componentProps}
                    slots={{toolbar: GridToolbar}}

                />
            </Box>
            <Box sx={{
                flexGrow: 1,
                display: {xs: 'flex', md: 'none'},
                height: 350,
                width: '100%'
            }}
            >
                <DataGrid
                    columns={columnsMobile}
                    density="compact"
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    disableRowSelectionOnClick={true}
                    filterMode="server"
                    initialState={{
                        pagination: {
                            paginationModel: {pageSize: perPage, page: 0},

                        }
                    }}
                    loading={isFetching}
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationMode="server"
                    rowCount={rowCount}
                    rows={rows}
                    slotProps={componentProps}
                    slots={{toolbar: GridToolbar}}

                />
            </Box>
        </Box>
    );


}