import {DataGrid, GridColDef, GridFilterModel, GridRenderCellParams, GridRowsProp, GridToolbar} from "@mui/x-data-grid";
import {Link} from "react-router-dom";
import {Box, IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import {ResultList} from "../../../types/ResultList";
import DeleteIcon from "@mui/icons-material/Delete";
import {FichaTecnica} from "../../../types/FichaTecnica";
import {findCategoria, getCategoria} from "../../../types/Categoria";
import {getUnidadeComercial} from "../../../types/UnidadeComercial";

type Props = {
    data: ResultList<FichaTecnica> | undefined,
    perPage: number,
    isFetching: boolean,
    handleOnPaginationModelChange: (perPage: number, page: number) => void;
    handleFilterChange: (filterModel: GridFilterModel) => void;
    handleDelete: (id: string) => void;

}

export default function FichaTecnicaTable({
                                        data,
                                        perPage,
                                        isFetching,
                                        handleOnPaginationModelChange,
                                        handleFilterChange,
                                        handleDelete
                                    }: Props) {


    console.log(data)
    const rows: GridRowsProp = data ? data.items.map((fichaTecnica) => ({
        id: fichaTecnica.id,
        descricao: fichaTecnica.descricao,
        categoria: fichaTecnica.categoria,
        rendimento: fichaTecnica.rendimento,
        custo: fichaTecnica.custo,
    })) : [];

    const nomeColumn: GridColDef = {
        field: 'descricao',
        headerName: 'Descrição',
        minWidth: 250
    }

    const categoriaColumn: GridColDef = {
        field: 'categoria',
        headerName: 'Categoria',
        minWidth: 125,
        renderCell: (params: GridRenderCellParams<any, string>  ) => {
            return findCategoria(params.value)?.label;
        }

    }

    const rendimentoColumn: GridColDef = {
        field: 'rendimento',
        headerName: 'Rendimento',
        minWidth: 125,
        renderCell: (params: GridRenderCellParams) => {
            return `${params.row.rendimento.valor} ${getUnidadeComercial(params.row.rendimento.unidade)}`;
        }
    }

    const valorBaseColumn: GridColDef = {
        field: 'custo',
        headerName: 'Custo Base',
        valueFormatter: (value) => {
            return `R$ ${value}`;
        },
        minWidth: 100
    }

    const valorPorUnidadeColumn: GridColDef = {
        field: 'valorPorRendimento',
        headerName: 'Valor por Rendimento',
        valueFormatter: (value, row) => {
            return `R$ ${ (row.custo / row.rendimento.valor).toFixed(2) } / ${getUnidadeComercial(row.rendimento.unidade)}`;
        },
        minWidth: 225
    }

    const acoesColumn: GridColDef = {
        field: 'id',
        headerName: 'Ações',
        renderCell: renderActionsCell
    }

    const columnsDesktop: GridColDef[] = [nomeColumn, categoriaColumn, rendimentoColumn, valorBaseColumn, valorPorUnidadeColumn, acoesColumn];

    function renderActionsCell(params: GridRenderCellParams) {
        return (
            <Box>
                <Link
                    to={`/fichas-tecnicas/${params.id}/edit`}
                >
                    <IconButton
                        color='secondary'
                        aria-label="edit"
                        size="small"
                    >
                        <EditIcon />
                    </IconButton>
                </Link>
                <IconButton
                    color="error"
                    onClick={() => handleDelete(params.value)}
                    aria-label="delete"
                    data-testid="delete-button"
                >
                    <DeleteIcon />
                </IconButton>
            </Box>
        );
    }

    const componentProps = {
        toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
                debounceMs: 500
            }
        },
    }

    const rowCount = data?.total ?? 0;


    return (
        <Box>
            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                height: '70%',
                width: '100%'
            }}>
                <DataGrid
                    columns={columnsDesktop}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    disableRowSelectionOnClick={true}
                    filterMode="server"
                    initialState={{
                        pagination: {
                            paginationModel: {pageSize: perPage, page: 0},
                        }
                    }}
                    loading={isFetching}
                    onFilterModelChange={handleFilterChange}
                    onPaginationModelChange={(pagination) => handleOnPaginationModelChange(pagination.pageSize, pagination.page)}
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationMode="server"
                    rowCount={rowCount}
                    rows={rows}
                    slotProps={componentProps}
                    slots={{toolbar: GridToolbar}}
                />
            </Box>
        </Box>
    );


}