enum Categoria {
    DOCES_FINOS = "Doces Finos",
    DOCES_TRADICIONAIS = "Doces Tradicionais",
    BOLOS = "Bolos",
    PERSONALIZADOS = "Personalizados",
    SAZONAIS = "Sazonais",
    CAIXAS = "Caixas",
    PASTA_AMERICANA = "Pasta Americana",
    CHOCOLATES = "Chocolates",
    ADICIONAIS = "Adicionais",
    NATAL = "Natal",
    PASCOA = "Páscoa",
    OUTROS = "Outros"
}

//get the value from key
export const getCategoria = (key: keyof typeof Categoria | undefined) => {
    if(!key) return '';
    return Categoria[key as keyof typeof Categoria];
}

export const categorias = Object.entries(Categoria).map(([option, label]) => ({ label, option }));

export const findCategoria = (option: string | undefined) => {
    return categorias.find(categoria => categoria.option === option);
}