import React from "react";
import CustomCard from "../../components/CustomCard";

export default function ProdutoCard() {
    return (
        <CustomCard
            linkAction={'/produtos'}
            name={'Produtos'}
            description={'Clique aqui para gerenciar os produtos'}
        />
    );
}