import {Navigate, Outlet} from "react-router-dom";
import {CircularProgress} from '@mui/material';
import {useAdminStatus} from "../app/hooks";

export default function  AdminProtectedRoute() {

    const { isAdmin, loading } = useAdminStatus();

    if (loading) {
        return <CircularProgress />;
    }

    return isAdmin ? <Outlet /> : <Navigate to="/" replace={true} />;

}

