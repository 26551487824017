import {Box, Button, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import {GridFilterModel} from "@mui/x-data-grid";
import {useDeleteFichaTecnicaMutation, useGetFichasTecnicasQuery} from "./produtoSlice";
import ProdutoTable from "./components/ProdutoTable";
import {enqueueSnackbar} from "notistack";

export default function ListProdutos() {

    return (
        <Box maxWidth="lg" sx={{mt: 4, mb: 4}}>

            <Typography variant='h3' component='h1'>
                Produtos
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        variant='contained'
                        color='secondary'
                        style={{marginBottom: "1rem", marginTop: "1rem" }}
                        component={Link}
                        to="/produtos/create"
                    >
                        Novo produto
                    </Button>
                </Box>
            </Typography>
            <Box display="flex" justifyContent="flex-start">
                <Button
                    variant='contained'
                    color='primary'
                    style={{marginTop: "1rem"}}
                    component={Link}
                    to="/"
                >
                    Voltar
                </Button>
            </Box>
        </Box>
    )
}
