import React from "react";

import CustomCard from "../../components/CustomCard";

export default function InsumoCard() {

    return (
        <CustomCard
            linkAction={'/insumos'}
            name={'Insumos'}
            description={'Clique aqui para gerenciar os insumos'}
        />
    );
}