import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {RootState, AppDispatch, setToken} from './store';
import { useEffect, useState } from 'react';
import { auth } from '../config/firebase-config';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export function useAdminStatus() {
    const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAdmin = async () => {
            try {
                const idTokenResult = await auth.currentUser?.getIdTokenResult();
                setIsAdmin(!!idTokenResult?.claims.admin);
            } catch (error) {
                setIsAdmin(false);
            } finally {
                setLoading(false);
            }
        };

        checkAdmin();
    }, []);

    return { isAdmin, loading };
}

export function useAuthToken() {
    const [token, setTokenState] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchToken = async () => {
            try {
                await auth.currentUser?.getIdToken().then((idToken) => {
                    //console.log("SToken: " + idToken);
                    setTokenState(idToken || null);
                    dispatch(setToken(idToken || null));
                });
            } catch (error) {
                setTokenState(null);
                dispatch(setToken(null));
            } finally {
                setLoading(false);
            }
        };

        fetchToken();
    }, [dispatch]);

    return { token, loading };
}