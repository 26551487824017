import {configureStore, ThunkAction, Action, PayloadAction, createSlice} from '@reduxjs/toolkit';
import {clientesApiSlice} from '../features/clientes/clienteSlice';
import { apiSlice } from '../features/api/apiSlice'

interface AuthState {
  token: string | null;
}

const initialState: AuthState = {
  token: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
    },
  },
});

export const { setToken } = authSlice.actions;


export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [clientesApiSlice.reducerPath]: clientesApiSlice.reducer,
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
      .concat(apiSlice.middleware)
      .concat(clientesApiSlice.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;