import {Box, Paper, SelectChangeEvent, Typography} from '@mui/material';
import 'dayjs/locale/pt-br';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import {useGetFichaTecnicaQuery, useUpdateFichaTecnicaMutation} from "./fichaTecnicaSlice";
import {FichaTecnica, QuantidadeInsumo} from "../../types/FichaTecnica";
import FichaTecnicaForm from "./components/FichaTecnicaForm";

export default function EditFichaTecnica() {
    const id = useParams().id ?? "";
    const navigate = useNavigate();

    const {data: fichaTecnica, isLoading} = useGetFichaTecnicaQuery({id});

    const [isDisabled, setIsDisabled] = useState(false);

    const [updateFichaTecnica, status] = useUpdateFichaTecnicaMutation()

    const [fichaTecnicaState, setFichaTecnicaState] = useState<FichaTecnica>({
        id: "",
        descricao: "",
        categoria: "",
        rendimento: {
            valor: 0,
            unidade: ""
        },
        quantidadeInsumos: [],
        modoPreparo: []
    });

    const [modoPreparoState, setModoPreparoState] = useState<string[]>(fichaTecnicaState.modoPreparo);
    const [quantidadeInsumosState, setQuantidadeInsumosState] = useState<QuantidadeInsumo[]>(fichaTecnicaState.quantidadeInsumos);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
        const {name, value} = e.target;
        if(name === "quantidadeRendimento") {
            setFichaTecnicaState(fichaTecnicaState => ({...fichaTecnicaState, rendimento: {...fichaTecnicaState.rendimento, valor: Number(value)}}));
            return;
        }
        setFichaTecnicaState(fichaTecnicaState => ({...fichaTecnicaState, [name]: value ?? ""}));
    };

    const handleChangeOnEnum = (event: any, name: string, newValue: { label: string, option: string } | null) => {
        if (!newValue) {
            return;
        }
        if(name === "unidadeRendimento") {
            setFichaTecnicaState(fichaTecnicaState => ({...fichaTecnicaState, rendimento: {...fichaTecnicaState.rendimento, unidade: newValue.option}}));
            return;
        }
        setFichaTecnicaState(fichaTecnicaState => ({...fichaTecnicaState, [name]: newValue.option}));
    };

    const handleModoPreparoChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const newModoPreparo = [...modoPreparoState];
        newModoPreparo[index] = e.target.value;
        setModoPreparoState(newModoPreparo);
    };


    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setFichaTecnicaState(fichaTecnicaState => ({
            ...fichaTecnicaState,
            quantidadeInsumos: quantidadeInsumosState,
            modoPreparo: modoPreparoState
        }));
        console.log(JSON.stringify(fichaTecnicaState))
        await updateFichaTecnica(fichaTecnicaState);
    }

    useEffect(() => {
        if (fichaTecnica) {
            setFichaTecnicaState(fichaTecnica);
            setModoPreparoState(fichaTecnica.modoPreparo);
            setQuantidadeInsumosState(fichaTecnica.quantidadeInsumos);
        }
    }, [fichaTecnica]);

    useEffect(() => {
        setFichaTecnicaState(fichaTecnicaState => ({
            ...fichaTecnicaState,
            quantidadeInsumos: quantidadeInsumosState
        }));
    }, [fichaTecnica, quantidadeInsumosState]);

    useEffect(() => {
        setFichaTecnicaState(fichaTecnicaState => ({
            ...fichaTecnicaState,
            modoPreparo: modoPreparoState
        }));
    }, [fichaTecnica, modoPreparoState]);

    useEffect(() => {
        if (status.isSuccess) {
            enqueueSnackbar("Ficha técnica atualizada com successo", {variant: "success"});
            setIsDisabled(true);
            navigate(-1);
        }
        if (status.isError) {
            enqueueSnackbar("Não foi possível ataualizar a ficha técnica", {variant: "error"});
        }
    }, [enqueueSnackbar, status.isError, status.isSuccess]);

    return (
        <Box>
            <Paper>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant='h4'>Editar ficha técnica</Typography>
                    </Box>
                </Box>

                <FichaTecnicaForm
                    fichaTecnica={fichaTecnicaState}
                    modoPreparoState={modoPreparoState}
                    setModoPreparoState={setModoPreparoState}
                    quantidadeInsumosState={quantidadeInsumosState}
                    setQuantidadeInsumosState={setQuantidadeInsumosState}
                    isUpdating={status.isLoading}
                    isLoading={isLoading}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleModoPreparoChange={handleModoPreparoChange}
                    handleChangeOnEnum={handleChangeOnEnum}
                />

            </Paper>
        </Box>
    )
}
