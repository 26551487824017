import {Box, Paper, SelectChangeEvent, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import FichaTecnicaForm from "./components/FichaTecnicaForm";
import {useNavigate} from "react-router-dom";
import {enqueueSnackbar} from "notistack";
import {useCreateFichaTecnicaMutation} from "./fichaTecnicaSlice";
import {FichaTecnica, QuantidadeInsumo} from "../../types/FichaTecnica";

export default function CreateFichaTecnica() {

    const navigate = useNavigate();

    const [isDisabled, setIsDisabled] = useState(false);

    const [createFichaTecnica, status] = useCreateFichaTecnicaMutation()

    const [fichaTecnicaState, setFichaTecnicaState] = useState<FichaTecnica>({
        id: "",
        descricao: "",
        categoria: "",
        rendimento: {
            valor: 0,
            unidade: ""
        },
        quantidadeInsumos: [],
        modoPreparo: []
    });

    const [modoPreparoState, setModoPreparoState] = useState<string[]>(fichaTecnicaState.modoPreparo);
    const [quantidadeInsumosState, setQuantidadeInsumosState] = useState<QuantidadeInsumo[]>(fichaTecnicaState.quantidadeInsumos);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
        const {name, value} = e.target;
        if(name === "quantidadeRendimento") {
            setFichaTecnicaState(fichaTecnicaState => ({...fichaTecnicaState, rendimento: {...fichaTecnicaState.rendimento, valor: Number(value)}}));
            return;
        }
        setFichaTecnicaState(fichaTecnicaState => ({...fichaTecnicaState, [name]: value ?? ""}));
    };

    const handleChangeOnEnum = (event: any, name: string, newValue: { label: string, option: string } | null) => {
        if (!newValue) {
            return;
        }
        if(name === "unidadeRendimento") {
            setFichaTecnicaState(fichaTecnicaState => ({...fichaTecnicaState, rendimento: {...fichaTecnicaState.rendimento, unidade: newValue.option}}));
            return;
        }
        setFichaTecnicaState(fichaTecnicaState => ({...fichaTecnicaState, [name]: newValue.option}));
    };

    const handleModoPreparoChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const newModoPreparo = [...modoPreparoState];
        newModoPreparo[index] = e.target.value;
        setModoPreparoState(newModoPreparo);
    }


    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setFichaTecnicaState(fichaTecnicaState => ({
            ...fichaTecnicaState,
            quantidadeInsumos: quantidadeInsumosState,
            modoPreparo: modoPreparoState
        }));
        console.log(fichaTecnicaState)
        await createFichaTecnica(fichaTecnicaState);
    }

    useEffect(() => {
        fichaTecnicaState.quantidadeInsumos = quantidadeInsumosState;
    }, [fichaTecnicaState, quantidadeInsumosState]);

    useEffect(() => {
        fichaTecnicaState.modoPreparo = modoPreparoState;
    }, [fichaTecnicaState, modoPreparoState]);

    useEffect(() => {
        if (status.isSuccess) {
            enqueueSnackbar("Ficha técnica cadastrada com successo", {variant: "success"});
            setIsDisabled(true);
            navigate(-1);
        }
        if (status.isError) {
            enqueueSnackbar("Não foi possível cadastrar a ficha técnica", {variant: "error"});
        }
    }, [enqueueSnackbar, status.isError, status.isSuccess]);

    return (
        <Box>
            <Paper>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant='h4'>Nova ficha técnica</Typography>
                    </Box>
                </Box>

                <FichaTecnicaForm
                    fichaTecnica={fichaTecnicaState}
                    modoPreparoState={modoPreparoState}
                    setModoPreparoState={setModoPreparoState}
                    quantidadeInsumosState={quantidadeInsumosState}
                    setQuantidadeInsumosState={setQuantidadeInsumosState}
                    isUpdating={status.isLoading}
                    isLoading={false}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleModoPreparoChange={handleModoPreparoChange}
                    handleChangeOnEnum={handleChangeOnEnum}
                />

            </Paper>
        </Box>
    )
}