import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InventoryIcon from '@mui/icons-material/Inventory';
import DescriptionIcon from '@mui/icons-material/Description';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../config/firebase-config";
import {useNavigate} from "react-router-dom";
import {signOut} from "firebase/auth";
import DashboardIcon from '@mui/icons-material/Dashboard';
import SquareIcon from '@mui/icons-material/Square';

const drawerWidth = 240;

export function Layout({children}: {children: React.ReactNode}) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const [user, loading, error] = useAuthState(auth);

    const navigate = useNavigate();

    const pages = [
        {pageName: 'Dashbaord', pagePath: '', icon: <DashboardIcon />},
        //{pageName: 'Clientes', pagePath: 'clientes', icon: <PersonIcon />},  // Person or Group icon
        {pageName: 'Insumos', pagePath: 'insumos', icon: <InventoryIcon />},  // Inventory for supplies
        {pageName: 'Fichas Técnicas', pagePath: 'fichas-tecnicas', icon: <DescriptionIcon />},  // Description for technical sheets
        {pageName: 'Embalagens', pagePath: 'embalagens', icon: <SquareIcon />},  // LocalShipping for packaging or delivery
        //{pageName: 'Produtos', pagePath: 'produtos', icon: <CategoryIcon />},  // Category for products

    ];

    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List>
                {pages.map((page) => (
                    <ListItem key={page.pageName} disablePadding>
                        <ListItemButton
                            onClick={() => {
                                navigate(`/${page.pagePath}`);
                            }}
                        >
                            <ListItemIcon>
                                {page.icon}
                            </ListItemIcon>
                            <ListItemText primary={page.pageName} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                <ListItem key={"Sair"} disablePadding>
                    <ListItemButton
                        onClick={() => {
                            signOut(auth).then(r => navigate('/'));
                        }}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Sair"} />
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    return (
        <>
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            { user &&
                <>
                    <AppBar
                        position="fixed"
                        sx={{
                            width: {sm: `calc(100% - ${drawerWidth}px)`},
                            ml: {sm: `${drawerWidth}px`},
                        }}
                    >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{mr: 2, display: {sm: 'none'}}}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <img src="/logo_docenela.png"></img>
                    </Toolbar>
                    </AppBar>
                    <Box
                        component="nav"
                        sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                        aria-label="mailbox folders"
                    >
                        <Drawer
                            variant="temporary"
                            open={mobileOpen}
                            onTransitionEnd={handleDrawerTransitionEnd}
                            onClose={handleDrawerClose}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: {xs: 'block', sm: 'none'},
                                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                            }}
                        >
                            {drawer}
                        </Drawer>
                        <Drawer
                            variant="permanent"
                            sx={{
                                display: {xs: 'none', sm: 'block'},
                                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                            }}
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Box>
                </> }
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                {children}
            </Box>
        </Box>
        </>
    );
}