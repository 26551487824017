import {Box, ThemeProvider, Typography} from '@mui/material';
import React from 'react';
import {Layout} from './components/Layout';
import {appTheme} from './config/theme';
import {Route, Routes} from 'react-router-dom';
import ListClientes from './features/clientes/ListClientes';
import CreateCliente from './features/clientes/CreateCliente';
import EditCliente from './features/clientes/EditCliente';
import HomePage from "./features/home/HomePage";
import {SnackbarProvider} from 'notistack'
import ListInsumos from "./features/insumos/ListInsumos";
import CreateInsumo from "./features/insumos/CreateInsumo";
import EditInsumo from "./features/insumos/EditInsumo";
import ListFichasTecnicas from "./features/fichastecnicas/ListFichasTecnicas";
import CreateFichaTecnica from "./features/fichastecnicas/CreateFichaTecnica";
import EditFichaTecnica from "./features/fichastecnicas/EditFichaTecnica";
import {Login} from "./components/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import ListEmbalagem from "./features/embalagens/ListEmbalagem";
import CreateEmbalagem from "./features/embalagens/CreateEmbalagem";
import EditEmbalagem from "./features/embalagens/EditEmbalagem";
import ListProdutos from "./features/produtos/ListProdutos";
import CreateProduto from "./features/produtos/CreateProduto";
import EditProduto from "./features/produtos/EditProduto";
import AdminProtectedRoute from "./components/AdminProtectedRoute";

function App() {
    return (
        <ThemeProvider theme={appTheme}>
            <SnackbarProvider
                autoHideDuration={2000}
                maxSnack={3}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
            >
                <Box component="main"
                     sx={{
                         height: "100vh"
                     }}
                >
                    <Layout>
                        <Routes>
                            <Route element={<ProtectedRoute/>}>
                                <Route path="/" element={<HomePage/>}/>

                                <Route path="/clientes" element={<ListClientes/>}/>
                                <Route path="/clientes/create" element={<CreateCliente/>}/>
                                <Route path="/clientes/:id/edit" element={<EditCliente/>}/>


                                <Route element={<AdminProtectedRoute/>}>

                                    <Route path="/insumos" element={<ListInsumos/>}/>
                                    <Route path="/fichas-tecnicas" element={<ListFichasTecnicas/>}/>
                                    <Route path="/embalagens" element={<ListEmbalagem/>}/>
                                    <Route path="/produtos" element={<ListProdutos/>}/>

                                    <Route path="/insumos/create" element={<CreateInsumo/>}/>
                                    <Route path="/insumos/:id/edit" element={<EditInsumo/>}/>

                                    <Route path="/fichas-tecnicas/create" element={<CreateFichaTecnica/>}/>
                                    <Route path="/fichas-tecnicas/:id/edit" element={<EditFichaTecnica/>}/>

                                    <Route path="/embalagens/create" element={<CreateEmbalagem/>}/>
                                    <Route path="/embalagens/:id/edit" element={<EditEmbalagem/>}/>

                                    <Route path="/produtos/create" element={<CreateProduto/>}/>
                                    <Route path="/produtos/:id/edit" element={<EditProduto/>}/>
                                </Route>

                            </Route>

                            <Route path="/login" element={<Login/>}/>

                            <Route path="*" element={
                                <Typography variant="h3" component="h1">404</Typography>
                            }/>
                        </Routes>
                    </Layout>
                </Box>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
