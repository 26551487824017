import {apiSlice, parseQueryParams} from "../api/apiSlice";
import {ResultList} from "../../types/ResultList";
import {InsumoRequest, InsumoResponse} from "../../types/Insumo";
import {QueryParams} from "../../types/QueryParams";

const endpointUrl: string = "/insumos";

function listInsumosUrl({ page = 0, perPage = 10, search = "" }) {
    const params = { page, perPage, search};
    return `${endpointUrl}?${parseQueryParams(params)}`;
}

function createInsumoMutation(insumo: InsumoRequest) {
    return { url: endpointUrl, method: "POST", body: insumo };
}

function updateInsumoMutation(insumo: InsumoRequest) {
    return {
        url: `${endpointUrl}/${insumo.id}`,
        method: "PUT",
        body: insumo,
    };
}

function deleteInsumoMutation(id: string) {
    return {
        url: `${endpointUrl}/${id}`,
        method: "DELETE"
    };
}

export const insumosApiSlice = apiSlice.injectEndpoints({
    endpoints: ({query, mutation}) => ({
        getInsumos: query<ResultList<InsumoResponse>, QueryParams>({
            query: listInsumosUrl,
            providesTags: ["Insumos"]
        }),
        getInsumo: query<InsumoResponse, { id : string }>({
            query: ({id}) => `${endpointUrl}/${id}`,
            providesTags: ["Insumos"]
        }),
        createInsumo: mutation<void, InsumoRequest>({
            query: createInsumoMutation,
            invalidatesTags: ["Insumos"],
        }),
        updateInsumo: mutation<void, InsumoRequest>({
            query: updateInsumoMutation,
            invalidatesTags: ["Insumos"],
        }),
        deleteInsumo: mutation<void, string>({
            query: deleteInsumoMutation,
            invalidatesTags: ["Insumos"],
        }),
    }),
});

export const {
    useGetInsumosQuery,
    useGetInsumoQuery,
    useCreateInsumoMutation,
    useUpdateInsumoMutation,
    useDeleteInsumoMutation
} = insumosApiSlice