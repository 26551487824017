import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {QueryParams} from "../../types/QueryParams";
import {auth} from "../../config/firebase-config";

const url = process.env.REACT_APP_API_URL || "http://localhost:8080/api";

const baseUrl = `${url}`;

export const apiSlice = createApi({
   reducerPath: "api",
   tagTypes: ["Ping", "Clientes", "Insumos", "FichaTecnica", "Embalagem", "Produto"],
    endpoints: (builder) => ({}),
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: async (headers, {getState}) => {
            await auth.currentUser?.getIdToken(true).then((idToken) => {
                headers.set('Authorization', `Bearer ${idToken}`);
            });

            return headers;
        },
    }),

});

export function parseQueryParams(params: QueryParams) {
    const query = new URLSearchParams();

    if (params.page) {
        query.append("page", params.page.toString());
    }

    if (params.perPage) {
        query.append("perPage", params.perPage.toString());
    }

    if (params.search) {
        query.append("search", params.search);
    }

    return query.toString();
}
