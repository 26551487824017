import {Box, Button, FormControl, Grid, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {auth} from '../config/firebase-config'
import {signInWithEmailAndPassword} from 'firebase/auth';
import {useNavigate} from "react-router-dom";
import {usePingMutation} from "../features/api/pingSlice";

export function Login() {

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const [ping, {isLoading}] = usePingMutation(); // Initialize the ping mutation hook


    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(null); // Reseta o erro ao tentar logar novamente


        try {
            //await ping().unwrap();
        } catch (error: any) {
            setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.' + JSON.stringify(error));
            return;
        }

        try {


            await signInWithEmailAndPassword(auth, email, password);

            navigate("/");


        } catch (error: any) {
            // Usando os códigos de erro do Firebase para mensagens de erro mais precisas
            switch (error.code) {
                case 'auth/invalid-email':
                    setError('Formato de e-mail inválido. Por favor, insira um endereço de e-mail válido.');
                    break;
                case 'auth/user-disabled':
                    setError('Esta conta de usuário foi desativada. Por favor, entre em contato com o suporte.');
                    break;
                case 'auth/user-not-found':
                case 'auth/wrong-password':
                    setError('Usuario ou senha incorreta. Por favor, tente novamente.');
                    break;
                default:
                    setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.');
            }
        }
    };

    if(isLoading) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="50vh"
            >
                <Typography variant="h4" gutterBottom>
                    Carregando...
                </Typography>
            </Box>
        )
    }
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
        >
            <form onSubmit={handleLogin}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h4" gutterBottom>
                            Login
                        </Typography>
                        {error && (
                            <Typography variant="body2" color="error">
                                {error}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                            <TextField
                                label="Email"
                                variant="outlined"
                                margin="normal"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                            <TextField
                                label="Password"
                                variant="outlined"
                                type="password"
                                margin="normal"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Login
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}