import {Box, Paper, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useCreateClienteMutation} from './clienteSlice';
import ClienteForm from './components/ClienteForm';
import {Cliente} from "../../types/Cliente";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {Dayjs} from "dayjs";

export default function CreateCliente() {
    const navigate = useNavigate();

    const [isDisabled, setIsDisabled] = useState(false);

    const [createCliente, status] = useCreateClienteMutation()

    const [clienteState, setClienteState] = useState<Cliente>({
        id: "",
        nome: "",
        telefone: "",
        email: "",
        cpf: "",
        data_nascimento: ""
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        console.log(name, value);
        setClienteState(clienteState => ({...clienteState, [name]: value}));
    };

    const handleDateChange = (date: Dayjs | null) => {
        if (date) {
            setClienteState(clienteState => ({...clienteState, data_nascimento: date.format('YYYY-MM-DD')}));
        }
    };

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        await createCliente(clienteState);
    }

    useEffect(() => {
        if (status.isSuccess) {
            enqueueSnackbar("Cliente cadastrado com successo", {variant: "success"});
            setIsDisabled(true);
            navigate(-1);
        }
        if (status.isError) {
            enqueueSnackbar("Não foi possível cadastrar o cliente", {variant: "error"});
        }
    }, [enqueueSnackbar, status.isError, status.isSuccess, navigate]);

    return (
        <Box>
            <Paper>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant='h4'>Novo cliente</Typography>
                    </Box>
                </Box>

                <ClienteForm
                    cliente={clienteState}
                    isDisabled={isDisabled}
                    isLoading={false}
                    handleChange={handleChange}
                    handleDateChange={handleDateChange}
                    handleSubmit={handleSubmit}
                />
            </Paper>
        </Box>
    )
}
