import React from "react";

import CustomCard from "../../components/CustomCard";

export default function EmbalagemCard() {

    return (
        <CustomCard
            linkAction={'/embalagens'}
            name={'Embalagens'}
            description={'Clique aqui para gerenciar as embalagens'}
        />
    );
}